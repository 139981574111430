import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "../common/Footer";
import { getUserDetails, userSettingData } from "../../utils/tools";
import Loader from "../common/Loader";
import { isTokenValid, removeSessions } from "../../utils/helper";

const PanelWrapper = () => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  let token = localStorage.getItem("userToken");
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  let loginToken = queryParameters.get("loginToken");
  let twoFactorEnabled = queryParameters.get("twoFactorEnabled");
  let role = queryParameters.get("role");
  let country = queryParameters.get("country");
  let count = queryParameters.get("count");
  let id = queryParameters.get("id");
  let path = queryParameters.get("path");

  const getDetails = async () => {
    setLoading(true);
    await getUserDetails();
    await userSettingData();
    setLoading(false);
  };

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    if (path) {
      localStorage.setItem("userToken", loginToken);
      localStorage.setItem("role", role);
      localStorage.setItem("country", country);
      localStorage.setItem("subItemId", id);
      localStorage.setItem("count", count);
      navigate(`/${path}`);
    } else if (loginToken) {
      let status = isTokenValid(loginToken);
      if (status.valid) {
        localStorage.setItem("userToken", loginToken);
        localStorage.setItem("role", role);
        localStorage.setItem("country", country);
        if (role === "admin" || role === "superAdmin") {
          navigate("/admin-setting");
        } else {
          navigate("/");
        }
      } else {
        removeSessions();
        window.location.href = "https://incorpify.tasc360.com/signin";
        // navigate("/signin");
      }
    } else if (token) {
      let status = isTokenValid(loginToken);
      if (status.valid) {
        if (localStorage.getItem('role') === "admin" || localStorage.getItem('role') === "superAdmin") {
          navigate("/admin-setting");
        } else {
          // navigate("/");
        }
      } else {
        removeSessions();
        window.location.href = "https://incorpify.tasc360.com/signin";
        // navigate("/signin");
      }
    } else {
      if (window.location.href.includes("track-request")) {
        sessionStorage.setItem(
          "redirectUrl",
          window.location.origin + "/request-list"
        );
      } else {
        sessionStorage.setItem("redirectUrl", window.location.href);
      }
      // navigate("/signin");
      window.location.href = "https://incorpify.tasc360.com/signin";
    }
  }, []);

  // useEffect(() => {
  //   if(localStorage.getItem('path')) {
  //     console.log('path not null');
  //     let newPath = localStorage.getItem('path');
  //     localStorage.removeItem('path');
  //     navigate(`/${newPath}`);
  //   } else if (
  //     localStorage.getItem("role") &&
  //     localStorage.getItem("role") === "customer"
  //   ) {
  //     if (token !== null && token !== undefined && token !== "") {
  //       let status = isTokenValid(token);
  //       if (status.valid) {
  //         // navigate("/");
  //       } else {
  //         removeSessions();
  //         window.location.href = "https://incorpify.tasc360.com/signin";
  //         // navigate("/signin");
  //       }
  //     } else {
  //       removeSessions();
  //       // navigate("/signin");
  //       window.location.href = "https://incorpify.tasc360.com/signin";
  //     }
  //   } else if (
  //     localStorage.getItem("role") &&
  //     (localStorage.getItem("role") === "admin" ||
  //       localStorage.getItem("role") === "superAdmin")
  //   ) {
  //     if (token !== null && token !== undefined && token !== "") {
  //       let status = isTokenValid(token);
  //       if (status.valid) {
  //         navigate("/admin-setting");
  //       } else {
  //         removeSessions();
  //         // navigate("/signin");
  //         window.location.href = "https://incorpify.tasc360.com/signin";
  //       }
  //     } else {
  //       removeSessions();
  //       // navigate("/signin");
  //       window.location.href = "https://incorpify.tasc360.com/signin";
  //     }
  //   } else if (loginToken != null) {
  //     // console.log('not null');
  //     localStorage.setItem("userToken", loginToken);
  //     localStorage.setItem("role", role);
  //     localStorage.setItem("country", country);
  //     localStorage.setItem("isTwoFactorEnabled", twoFactorEnabled);
  //     if (role === "admin" || role === "superAdmin") {
  //       navigate("/admin-setting");
  //     } else {
  //       if(path) {
  //         localStorage.setItem("subItemId", id);
  //         localStorage.setItem("count", count);
  //         localStorage.setItem('path', path);
  //         // console.log('cscscscscscs');
  //         navigate(`/${path}`);
  //       } else{
  //         // console.log('else');
  //         navigate("/");
  //       }
  //     }
    // } else {
    //   if (window.location.href.includes("track-request")) {
    //     console.log('cdacaccadvvsvsvsvs');
    //     sessionStorage.setItem(
    //       "redirectUrl",
    //       window.location.origin + "/request-list"
    //     );
    //   } else {
    //     sessionStorage.setItem("redirectUrl", window.location.href);
    //   }
    //   // navigate("/signin");
    //   window.location.href = "https://incorpify.tasc360.com/signin";
    // }
  // }, []);

  if (loading || path) {
    return <Loader />;
  }

  return (
    <>
      <Header showOnlyLogo={false} />
      <div className="container d-flex flex-column h-100 text-center">
        <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PanelWrapper;
