import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/Home";
import PanelWrapper from "./components/wrapper/PanelWrapper";
import ErrorPage from "./components/fallbacks/ErrorPage";
import TrackRequest from "./components/TrackRequest";
import RequestListing from "./components/RequestListing";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ForgetPassword from "./components/auth/ForgetPassword";
import { AdminSettings } from "./components/settings.jsx/AdminSettings";
import CreatePassword from "./components/auth/CreatePassword";
import VerifyUser from "./components/auth/VerifyUser";
import TwoFactor from "./components/TwoFactor";
import { ConfigProvider } from "antd";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <PanelWrapper />,
          children: [
            {
              path: "",
              element: <Home />,
            },
            {
              path: "request-list",
              element: <RequestListing />,
            },
            {
              path: "track-request",
              element: <TrackRequest />,
            },
            {
              path: "admin-setting",
              element: <AdminSettings />,
            },
          ],
        },
        // {
        //   path: "signup",
        //   element: <Register />,
        // },
        // {
        //   path: "signin",
        //   element: <Login />,
        // },
        // {
        //   path: "forget-password",
        //   element: <ForgetPassword />,
        // },
        // {
        //   path: "reset-password",
        //   element: <CreatePassword />,
        // },
        // {
        //   path: "incorpify/verify",
        //   element: <VerifyUser />,
        // },
        // {
        //   path: "signin/two-factor",
        //   element: <TwoFactor />,
        // },
        {
          path: "*",
          element: <>NO PAGE FOUND</>,
        },
      ],
    },
  ]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "#497ed8",
            headerColor: "#f0f0f0",
          },
          Card: {
            headerBg: "#497ed8",
          },
          Checkbox: {
            colorPrimary: "#487ED8",
            colorPrimaryHover: "#487ED8",
          },
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
