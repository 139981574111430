import { MenuOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import React from "react";
// import { Credentials } from "../../utils/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { Calendar, LogOut } from "../../assets/Icons";

const CustomerMenu = ({ logoutFunction, setIsHelpModalOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const items = [
    {
      label: (
        <div
          className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
          onClick={() => {
            setIsHelpModalOpen(true);
          }}
        >
          <Calendar />
          <span
            className="fs-6 ff-ws"
            style={{
              color: location.pathname === "/portals" ? "#59C080" : "#454545",
            }}
          >
            Book a meeting
          </span>
        </div>
      ),
      key: "portal-credentials",
    },
    {
      label: (
        <div
          className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
          onClick={() => logoutFunction()}
        >
          <span
            className="icon-btn_track"
            style={{
              height: "22px",
              width: "30px",
            //   paddingLeft: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <LogOut />
          </span>
          <span className="fs-6 ff-ws" style={{ color: "#EF4444" }}>
            Logout
          </span>
        </div>
      ),
      key: "logout",
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      placement="bottomRight"
      className=""
    >
      <MenuOutlined
        style={{ color: "#ff6221", fontSize: "26px" }}
        onClick={(e) => e.preventDefault()}
      />
    </Dropdown>
  );
};

export default CustomerMenu;
