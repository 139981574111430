import { jwtDecode } from "jwt-decode";

export function isTokenValid(token) {
  if (!token) return { valid: false, error: "Token is empty" };

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      return { valid: false, error: "Token is expired" };
    }

    return { valid: true, decoded };
  } catch (err) {
    return { valid: false, error: "Invalid token" };
  }
}

export const fetcher = async (endpoint, method, payload = null, country = null) => {
  const token = getToken();
  let headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `bearer ${token}`);
  let url = `https://onboardifyapi.ksa.tasc360.com${endpoint}`;
  if(country) {
    url = `https://onboardifyapi.${country.toLowerCase()}.tasc360.com${endpoint}`;
  } else if (window.location.origin.includes("ksa")) {
    url = `https://onboardifyapi.ksa.tasc360.com${endpoint}`;
  } else if (window.location.origin.includes("uae")) {
    url = `https://onboardifyapi.uae.tasc360.com${endpoint}`;
  }

  let requestOptions = {
    method,
    headers: headers,
  };
  if (payload) {
    requestOptions.body = payload;
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  return data;
};

export const getRole = () => {
  let role = localStorage.getItem("role") || "superAdmin";
  return role;
};

const getToken = () => {
  let token = localStorage.getItem("userToken");
  return token;
};

export function removeSessions() {
  localStorage.clear();
  sessionStorage.clear();
}

export async function fetchBoardIdsColumn(id) {
  const url = `/incorpify/admin/getBoardColumnsIds/${id}`;
  const method = "GET";
  let data = [];
  try {
    const res = await fetcher(url, method);
    if (res.success) {
      data = res.data;
    }
  } catch (error) {}

  return data;
}

export async function getEmailsData(userEmails) {
  const url = "/tasc360/getEmailData";
  const method = "POST";
  let data = [];
  let payload = JSON.stringify({
    emails: userEmails,
  });
  try {
    const res = await fetcher(url, method, payload);
    if (res.status == "success") {
      data = res.data;
    }
  } catch (error) {}

  return data;
}
